.main {
    padding-top: 1em;
}

.main h1 {
    
}

.main__card {
    margin: 1em;
    border: 1px solid #243049;
}