.mom {
    margin: 1em 0.5em;
}

.mom > img {
    max-width: 400px;
    width: 100%;
}

.mom img + img {
    margin-top: 1em;
}