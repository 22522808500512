.navbar {
    border: 1px solid #243049;
    background-color: #1d273b;
}

.navbar-navbtns {
    margin: 0 auto;
}

.navbar h2 {
    color: #FFFFFF;
    margin: 1em auto;
}