.dad {
    margin-top: 1em;
}

.dad > img {
    max-width: 400px;
    width: 100%;
}

.dad img + img {
    margin-top: 1em;
}